import {Component, ComponentRef, HostListener, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ExamSession, ExerciseSession, ExerciseSessionQuestion} from "../cspa/personal";
import {Observable, Subscription, switchMap, tap, zip} from "rxjs";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {AnswerDefinitionBase, QuestionDefinitionBase} from "../cspa/questions";
import {QuestionActionRequest, QuestionActionRequestWithAnimation, QuestionActionRequestWithValue} from "../../../model/prototypes";
import {LoadQuestionDirective} from "../../directives/load-question.directive";
import {ExerciseSetEnum} from "../cspa/struct";
import {ConfirmDialogService} from "../../../utils/services/confirm-dialog.service";
import {TranslateService} from "@ngx-translate/core";
import {QuestionSetSummaryComponent} from "../../components/question-set-summary/question-set-summary.component";
import {CorrectionComponent} from "../../components/exercises/additional_exercises/correction/correction.component";
import {DictComponent} from "../../components/exercises/dict/dict.component";
import {FillMultiComponent} from "../../components/exercises/additional_exercises/fill-multi/fill-multi.component";
import {PicComponent} from "../../components/exercises/additional_exercises/pic/pic.component";
import {JmblComponent} from "../../components/exercises/additional_exercises/jmbl/jmbl.component";
import {QaComponent} from "../../components/exercises/qa/qa.component";
import {AudioComponent} from "../../components/exercises/additional_exercises/audio/audio.component";
import {OffcanvasMenuService} from "../../../services/offcanvas-menu.service";
import {ShortcutService} from "../../services/shortcut.service";
import {QuestionService} from "../../services/api/impl/rest/question.service";
import {SessionInfoService} from "../../../services/session-info.service";
import { FontSizeService } from "src/app/services/font-size.service";

@Component({
  template: '',
  styleUrls: ['./host-base.component.scss']
})
export abstract class HostBaseComponent<S extends ExerciseSession | ExamSession> implements OnInit, OnDestroy {

  questionNb: string
  session?: S
  uuId: string
  isCheckBtnBlocked = true
  isFinished = false
  question: ExerciseSessionQuestion<AnswerDefinitionBase, QuestionDefinitionBase<AnswerDefinitionBase>>
  questionRequests?: (QuestionActionRequest | QuestionActionRequestWithValue | QuestionActionRequestWithAnimation)[] = [null]
  @ViewChild(LoadQuestionDirective, {static: true}) loadQuestion!: LoadQuestionDirective
  private chapter: string
  private exerciseSet: string
  private isHintBtnShown = false
  private loadDataSubscription: Subscription
  private basePath: string
  private currentQuestionOpenTime: number;
  isVolumeRangeShown = false

  protected constructor(
    protected route: ActivatedRoute,
    private confirmDialogService: ConfirmDialogService,
    private translateService: TranslateService,
    private offcanvasMenuService: OffcanvasMenuService,
    private shortcut: ShortcutService,
    private questionService: QuestionService,
    protected progressInfoService: SessionInfoService,
    protected fontSizeService: FontSizeService
  ) {
  }

  @HostListener('window:keydown.enter', ['$event'])
  onEnterClick(event: KeyboardEvent) {
    if (event.type === 'keydown' && event.key === 'Enter' && !this.isCheckBtnBlocked) {
      this.onCheck()
    }
  }

  ngOnInit(): void {
    this.loadDataSubscription = this.prepareBasePath().pipe(
      switchMap(_ => this.route.paramMap),
      tap((params: ParamMap) => {
        this.questionNb = params.get("qNb");
        this.uuId = params.get("sessionId")
        this.chapter = params.get("chapter");
        this.exerciseSet = params.get("exerciseSet");
        this.retrieveFromParamMap(params)
        if (this.session) this.goToQuestion(params.get("qNb"))
        this.isFinished = this.questionNb === 'finish'
      }),
      switchMap(_ => this.getSession()),
    ).subscribe(_ => {
      this.questionNb === 'finish' && this.onLoadFinish()
    })
    this.offcanvasMenuService.setMenu("exerciseOptions")
    this.fontSizeService.setFromLocalStorage()
  }

  ngOnDestroy(): void {
    this.loadDataSubscription.unsubscribe()
    this.offcanvasMenuService.setMenu("defaultHamburger")
    if(!this.session.path.includes("exam")) this.fontSizeService.setToDefault()
  }

  abstract retrieveFromParamMap(params: ParamMap): void

  abstract getQuestions(): ExerciseSessionQuestion<any, any>[]

  abstract getExerciseName(): string

  abstract goToQuestion(index: number | string): void

  abstract getSession(): Observable<S>

  abstract finishSession(): Observable<S>

  abstract goBack(): void

  abstract postAnswerAndGoToQuestion(): void

  abstract prepareAudioProgressEmitter(componentRef: ComponentRef<DictComponent>): void

  getExerciseType() {
    return this.session?.sectionName.split(',')[0]
  }

  getChapter() {
    return this.session?.chapterName
  }

  getExerciseSet() {
    return ExerciseSetEnum[this.exerciseSet]
  }

  getExerciseRouterLink() {
    return `/${this.basePath}`
  }

  getChapterRouterLink() {
    return `/${this.basePath}/${this.exerciseSet}/${this.chapter}`
  }

  getQuestionType() {
    return this.getQuestion()?.question.definition['@type']
  }

  getQuestion() {
    return this.getQuestions() && this.getQuestions()[+this.questionNb - 1]
  }

  isBtnBlocked() {
    return this.isCheckBtnBlocked
  }

  isHintShown() {
    return this.isHintBtnShown
  }

  goToPrevious() {
    if (+this.questionNb > 1)
      this.goToQuestion(+this.questionNb - 1)
  }

  onFinish() {
    this.finishSession().subscribe()
  }

  endSession() {
    const title = this.translateService.instant("EXERCISES.END.END_SESSION_EXERCISE.TITLE")
    const content = this.translateService.instant("EXERCISES.END.END_SESSION_EXERCISE.CONTENT")
    const yesDecision = this.translateService.instant("EXERCISES.END.END_SESSION_EXERCISE.YES")
    const noDecision = this.translateService.instant("EXERCISES.END.END_SESSION_EXERCISE.NO")
    this.confirmDialogService.doOnConfirm(content, () => this.goBack(), title, '', yesDecision, noDecision)
  }

  onShortcutClick(btn: string) {
    this.shortcut.updateRequest(btn)
  }

  getHint() {
    this.shortcut.getHint()
  }

  setupQuestionTimings(question: ExerciseSessionQuestion<AnswerDefinitionBase,
    QuestionDefinitionBase<AnswerDefinitionBase>>) {
    if (!question.startDate) {
      question.startDate = new Date().getTime();
    }
    if (question.timeSpent == null) {
      question.timeSpent = 0;
    }
    this.currentQuestionOpenTime = new Date().getTime();
  }

  onCheck() {
    this.questionService.askForAnswer(true, this.getQuestionType())
    this.questionService.askForAnswer(false, '')
    this.progressInfoService.setSessionInfo({questionNb: this.questionNb, session: this.session})
  }

  onLoadQuestion(question: ExerciseSessionQuestion<AnswerDefinitionBase,
    QuestionDefinitionBase<AnswerDefinitionBase>>) {
    this.loadQuestion.viewContainerRef.clear();
    this.setupQuestionTimings(question)
    let componentRef: any;
    this.isCheckBtnBlocked = true
    this.isHintBtnShown = false
    this.questionRequests = [null] //array with null because it takes space which would be taken by additional icons
    let progress: number = null
    if (this.getQuestionType() === 'cor') {
      componentRef = this.loadQuestion.viewContainerRef.createComponent(CorrectionComponent)
      this.prepareQuestionEmitter(componentRef)
      this.prepareInProgressEmitter(componentRef)
      this.blockCheckBtn(false)
    } else if (this.getQuestionType() === 'dict') {
      progress = 0
      componentRef = this.loadQuestion.viewContainerRef.createComponent(DictComponent)
      componentRef.instance.isExam = this.session.path.includes("exam")
      this.prepareQuestionEmitter(componentRef)
      this.prepareAudioProgressEmitter(componentRef)
      this.blockCheckBtn(false)
      componentRef.instance.updateActionMenu.subscribe((qaRequest: (QuestionActionRequest | QuestionActionRequestWithValue)[]) => {
        this.questionRequests = qaRequest
        this.setRangeStyle(this.getQaRequestValue(qaRequest[0]))
      })
    } else if (this.getQuestionType() === 'ef') {
      componentRef = this.loadQuestion.viewContainerRef.createComponent(FillMultiComponent)
      this.prepareQuestionEmitter(componentRef)
      this.blockCheckBtn(false)
      if(this.session.path.includes("exam")) this.showHint(false)
      else this.showHint(true)
    } else if (this.getQuestionType() === 'pct') {
      componentRef = this.loadQuestion.viewContainerRef.createComponent(PicComponent)
      this.prepareQuestionEmitter(componentRef)
      this.prepareInProgressEmitter(componentRef)
    } else if (this.getQuestionType() === 'jmb') {
      componentRef = this.loadQuestion.viewContainerRef.createComponent(JmblComponent)
      this.prepareQuestionEmitter(componentRef)
      this.prepareInProgressEmitter(componentRef)
    } else if (this.getQuestionType() === 'qa') {
      componentRef = this.loadQuestion.viewContainerRef.createComponent(QaComponent)
      this.prepareQuestionEmitter(componentRef)
      componentRef.instance.updateActionMenu.subscribe((qaRequest: (QuestionActionRequest | QuestionActionRequestWithValue)[]) =>
        this.questionRequests = qaRequest
      )
      this.prepareInProgressEmitter(componentRef)
    } else if (this.getQuestionType() === 'audio') {
      componentRef = this.loadQuestion.viewContainerRef.createComponent(AudioComponent)
      this.prepareQuestionEmitter(componentRef)
      componentRef.instance.updateActionMenu.subscribe((qaRequest: (QuestionActionRequest | QuestionActionRequestWithValue | QuestionActionRequestWithAnimation)[]) => {
        this.questionRequests = qaRequest
        this.setRangeStyle(this.getQaRequestValue(qaRequest[0]))
      })
      this.prepareInProgressEmitter(componentRef)
    }

    componentRef.instance.question = question
    this.progressInfoService.setSessionInfo({questionNb: this.questionNb, session: this.session, progressPercent: progress})
  }

  isSummaryDisplayed() {
    return this.questionNb === 'finish'
  }

  getTooltipInfo() {
    switch (this.getQuestionType()) {
      case 'qa':
        return 'EXERCISES.HOST.TOOLTIP_INFO_QA'
      case 'jmb':
        return 'EXERCISES.HOST.TOOLTIP_INFO_JMB'
      case 'audio':
        return 'EXERCISES.HOST.TOOLTIP_INFO_AUDIO'
      case 'pct':
        return 'EXERCISES.HOST.TOOLTIP_INFO_PCT'
      default:
        return null
    }
  }

  private prepareBasePath() {
    return zip(this.route.parent.url, this.route.url).pipe(
      tap(([p, c]) => {
        p.push(...c.slice(0, 1))
        this.basePath = p.join("/")
      })
    )
  }

  private applyQuestionTiming() {
    if (!this.question.submitDate) {
      this.question.submitDate = new Date().getTime();
    }
    this.question.timeSpent += new Date().getTime() - this.currentQuestionOpenTime;
    this.currentQuestionOpenTime = new Date().getTime();
  }

  private blockCheckBtn(isBlocked: boolean) {
    this.isCheckBtnBlocked = isBlocked
  }

  private showHint(show: boolean) {
    this.isHintBtnShown = show
  }

  private onLoadFinish() {
    this.finishSession().subscribe(finishedSession => {
      let componentRef: any;
      this.loadQuestion.viewContainerRef.clear();
      componentRef = this.loadQuestion.viewContainerRef.createComponent(QuestionSetSummaryComponent)
      componentRef.instance.session = finishedSession
      componentRef.instance.uuid = this.uuId
    });
  }

  private prepareQuestionEmitter(componentRef: any) {
    componentRef.instance.questionEmitter.subscribe((question: ExerciseSessionQuestion<AnswerDefinitionBase, QuestionDefinitionBase<AnswerDefinitionBase>>) => {
      this.question = question as ExerciseSessionQuestion<AnswerDefinitionBase, QuestionDefinitionBase<AnswerDefinitionBase>>
      if (this.question.answer) {
        this.question.answer = question.answer
        this.applyQuestionTiming()
        this.postAnswerAndGoToQuestion()
      }
    })
  }

  private prepareInProgressEmitter(componentRef: any) {
    componentRef.instance.inProgressEmitter.subscribe((isBlocked: boolean) =>
      this.blockCheckBtn(isBlocked)
    )
  }

  getQaRequestValue(qar: QuestionActionRequest | QuestionActionRequestWithValue) {
    return (<QuestionActionRequestWithValue>qar).value
  }

  setRangeStyle(volume: number) {
    document.body.style.setProperty("--volume", volume + '%')
  }

  getAnimation(qar: QuestionActionRequest | QuestionActionRequestWithValue | QuestionActionRequestWithAnimation){
    return (<QuestionActionRequestWithAnimation>qar).animation
  }
}
